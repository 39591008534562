.footer {
  box-sizing: border-box;
  text-align: left;
  padding: 40px 0;
  width: 100%;
  background-color: var(--primary);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.footer-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.footer-col {
  width: 100%;
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .footer-col {
    width: 33%;
  }
}

@media (min-width: 1200px) {
  .footer-col {
    width: 33%;
    margin-bottom: 0;
  }
}

.footer p {
  color: white;
  font-size: 15px;
  line-height: 19px;
  margin: 5px 0;
}

.footer a {
  color: white;
  text-decoration: none;
  font-size: 15px;
  line-height: 19px;
  margin: 5px 0;
  display: block;
}

.footer a:hover {
  color: var(--secondary);
}

.footer-logo {
  height: 70px;
  margin-top: 5px;
}
