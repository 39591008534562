.content p {
  font-family: 'avenir';
  color: var(--primary);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1px;
  margin: 0;
  text-align: left;
}

ul {
  padding-left: 17px;
}

li {
  font-family: 'avenir';
  color: var(--primary);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1px;
  margin: 0;
  text-align: left;
}

.content {
  padding: 80px 0;
  /* min-height: 100vh; */
}

.dienstleistungen {
  position: relative;
  margin-top: 30px;
  margin-bottom: 155px;
}

/* .content-outer:nth-child(even) {
  background-color: rgba(var(--primaryRGB), 0.1);
} */

.content-wrapper {
  display: block;
  margin-bottom: 60px;
}

.content-wrapper__Inner {
  width: 100%;
  padding-right: 0;
}

.image-wrapper__Outer {
  width: 100%;
  padding-left: 0;
  margin-top: 50px;
}

.image-wrapper__Inner {
  height: 300px;
  width: auto;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .image-wrapper__Inner {
    height: 400px;
  }
  .dienstleistungen {
    margin-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .content-wrapper {
    display: flex;
    margin-bottom: 30px;
  }
  .content-wrapper__Inner {
    width: 50%;
    padding-right: 20px;
  }
  .image-wrapper__Outer {
    width: 50%;
    padding-left: 20px;
    margin-top: 0;
  }
  .image-wrapper__Inner {
    height: 400px;
  }
  .content p {
    font-size: 16px;
    line-height: 24px;
  }
  .content {
    padding: 130px 0;
  }
  .dienstleistungen {
    margin-bottom: 80px;
  }
}

@media (min-width: 1200px) {
  .image-wrapper__Inner {
    height: 400px;
  }
  .content p {
    font-size: 18px;
    line-height: 26px;
  }

  li {
    font-size: 18px;
    line-height: 26px;
  }
  .content {
    padding: 120px 0;
  }
}

@media (min-width: 1440px) {
  .dienstleistungen {
    margin-bottom: 0;
  }
}
