.header {
  box-sizing: border-box;
  padding: 20px 0;
  width: 100%;
  height: 90px;
  background-color: var(--primary);
}

.Header-logo {
  max-height: 50px;
}

@media (min-width: 768px) {
  .header {
    height: 110px;
  }

  .Header-logo {
    max-height: 70px;
  }
}

/* @media (min-width: 1200px) {
  .header {
    height: 110px;
  }
  
  .Header-logo {
    max-height: 70px;
  }
} */
