:root {
  --primary: #003d58;
  --primaryRGB: 0, 61, 88;
  --secondary: #e57e3e;
}

@font-face {
  font-family: 'avenir';
  src: local('avenir'),
    url(./assets/fonts/AvenirNextLTPro-Regular.otf) format('truetype');
}

@font-face {
  font-family: 'CenturyGothic';
  src: local('CenturyGothic'),
    url(./assets/fonts/CenturyGothicStdRegular.otf) format('truetype');
}

h1 {
  position: relative;
  font-family: 'CenturyGothic';
  color: var(--primary);
  font-size: 26px;
  line-height: 38px;
  margin-bottom: 40px;
  text-align: left;
}

@media (min-width: 768px) {
  h1 {
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 60px;
    text-align: center;
  }
}

@media (min-width: 1200px) {
  h1 {
    font-size: 42px;
    line-height: 56px;
  }
}

/* h1::before {
  content: '';
  width: 80px;
  height: 2px;
  background-color: var(--secondary);
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
} */

h2 {
  font-family: 'CenturyGothic';
  text-align: left;
  color: var(--primary);
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 15px;
  margin-top: 0;
}

@media (min-width: 768px) {
  h2 {
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 20px;
  }
}

@media (min-width: 1200px) {
  h2 {
    /* font-size: 28px;
    line-height: 38px; */
  }
}

h3 {
  font-family: 'CenturyGothic';
  text-align: left;
  color: var(--primary);
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 15px;
  }
}

@media (min-width: 1200px) {
  h3 {
    /* font-size: 28px;
    line-height: 38px; */
  }
}

body {
  box-sizing: border-box;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  box-sizing: border-box;
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container-sm,
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1320px;
  }
}
